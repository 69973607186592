import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import { breakpoint, color, radius } from '../theme';

const Slide = styled.div`
  flex: 0 0 auto;
  width: 75%;
  padding: 0 0.5rem;
  position: relative;

  @media ${breakpoint.md} {
    width: 56%;
  }
`;

const Image = styled(GatsbyImage)`
  display: block;
  background-color: ${color.grey[10]};
  border-radius: ${radius.md};
  position: relative;

  img {
    border-radius: ${radius.md};
  }
`;

export const CarouselSlide = ({ image, alt, backgroundColor }) => {
  const backgroundColorProp = backgroundColor ? backgroundColor = { backgroundColor } : null;
  return (
    <Slide className="embla__slide">
      <Image image={image} {...backgroundColorProp} alt={alt} />
    </Slide>
  );
};
