import React, { useEffect, useState } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import styled from 'styled-components';

const Wrapper = styled.section`
  height: auto;
  overflow: hidden;

  .is-draggable {
    cursor: move;
    cursor: grab;
  }

  .is-dragging {
    cursor: grabbing;
  }
`;

const Container = styled.div`
  opacity: ${({ $emblaHasMounted }) => $emblaHasMounted ? 1 : 0};
  transition: opacity 0.5s;
  display: flex;
  width: 100vw;
  justify-content: stretch;
`;

const CarouselWrapper = ({ children }) => {
  const [emblaHasMounted, setEmblaHasMounted] = useState(false);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
  });

  useEffect(() => {
    if (emblaApi && !emblaHasMounted) {
      // Embla API is ready
      setEmblaHasMounted(true);
    }
  }, [emblaApi, emblaHasMounted, setEmblaHasMounted]);

  return (
    <Wrapper>
      <div className="embla" ref={emblaRef}>
        <Container className="embla__container" $emblaHasMounted={emblaHasMounted}>
          {children}
        </Container>
      </div>
    </Wrapper>
  );
};

export default CarouselWrapper;
