import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import { breakpoint, color, typography } from '../../theme';
import { DefaultGrid } from '../../theme/grid';
import { Button } from '../general/Button';

const Grid = styled(DefaultGrid)`
  gap: 1rem;
`;

const Image = styled(GatsbyImage)`
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 66%;
  margin-left: auto;
  margin-right: auto;

  @media ${breakpoint.sm} {
    width: 50%;
  }
  @media ${breakpoint.md} {
    width: 100%;
    margin-bottom: 0;
    grid-column: 1 / 6;
  }

  img {
    border-radius: 50%;
  }
`;

const TextWrapper = styled.div`
  text-align: center;

  @media ${breakpoint.md} {
    text-align: left;
    grid-column: 7 / -1;
  }

`;

const Title = styled.h3`
  font-family: ${typography.family.title};
  font-size: ${typography.size.f3};
  color: ${color.grey[90]};
  margin-bottom: 0.25em;

  @media ${breakpoint.sm} {
    font-size: ${typography.size.f2};
  }

  @media ${breakpoint.md} {
    font-size: ${typography.size.f1};
  }
`;

const Description = styled.p`
  font-size: ${typography.size.f6};
  color: ${color.grey[60]};
  letter-spacing: ${typography.spacing.tight}em;
  margin-bottom: 1em;

  @media ${breakpoint.sm} {
    font-size: ${typography.size.f5};
  }
  @media ${breakpoint.md} {
    font-size: ${typography.size.f4};
  }
  @media ${breakpoint.md} {
    font-size: ${typography.size.f4};
  }
`;

export const BookNowBanner = () => {

  const data = useStaticQuery(graphql`{
  file(relativePath: {eq: "home/knots-sports-massage-therapy.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        quality: 60
        width: 400
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
}
`);

  return (
    <Grid dense center>
      <Image image={data.file.childImageSharp.gatsbyImageData} backgroundColor={`${color.grey[15]}`} alt="Sports massage practitioner working on a client" />
      <TextWrapper>
        <Title>Book A Treatment</Title>
        <Description>Let's start off with a consultation and plan your path to rehabilitation.</Description>
        <Button text="Contact Us" url="/contact/" title="Contact Us" color={color.white} backgroundColor={color.grey[90]} />
      </TextWrapper>
    </Grid>
  );
};
