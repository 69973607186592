import React from 'react';
import styled from 'styled-components';
import { breakpoint, color, radius, shadow, typography } from '../../theme';

const Container = styled.div`
  & span {
    text-decoration: underline;
    color: ${color.brand[50]};
    text-decoration-color: ${color.brand[50]};
    
    @media (hover:hover) {
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
`;

const Title = styled.h2`
  font-family: ${typography.family.title};
  font-size: ${typography.size.f5};
  margin-bottom: 0.5rem;

  @media ${breakpoint.lg} {
    font-size: ${typography.size.f4};
    margin-bottom: 1rem;
  }
`;

const Description = styled.p`
  color: ${color.grey[70]};

  @media ${breakpoint.lg} {
    font-size: ${typography.size.f5};
  }
`;

const OverviewContent = ({title, children}) => {

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{children}</Description>
    </Container>
  );
};

export default OverviewContent;
