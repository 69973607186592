import React from 'react';
import styled from 'styled-components';
import { breakpoint, color, radius, shadow, typography } from '../../theme';
import FiveStars from '../general/FiveStars';

const Container = styled.div`
  background-color: ${color.white};
  border-radius: ${radius.md};
  padding: 2rem;
  text-align: center;
  width: 80vw;
  box-shadow: ${shadow.box.card};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  margin-right: 1rem;
  height: auto;

  &:last-child {
      margin-right: 0rem;
    }

  @media ${breakpoint.sm} {
    width: 24rem;
    height: fit-content;
  }

  @media ${breakpoint.md} {
    margin-right: 0rem;
    width: 32%;
  }

  svg {
    margin-bottom: 2rem;
  }
`;

const Description = styled.p`
  color: ${color.grey[90]};
  /* line-height: 1.8; */
  margin-bottom: 2rem;
`;

const Reviewer = styled.p`
  font-size: ${typography.size.f7};
  font-weight: bold;
  color: ${color.brand[50]};
`;

const Review = ({ title, review, reviewer }) => {
  return (
    <Container>
      <FiveStars width="10rem" height="1.75rem" title="five star review" color={`${color.brand[50]}`} />
      <Description>{review}</Description>
      <Reviewer>{reviewer}</Reviewer>
    </Container>
  );
};

export default Review;
