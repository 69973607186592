import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import SEO from '../components/core/Seo';
import OverviewContent from '../components/home-page/OverviewContent';
import Carousel from '../components/Carousel';
import Layout from '../layouts/Layout';
import { breakpoint, color, typography, width, radius, shadow } from '../theme';
import { TexturedBackground } from '../theme/background';
import { DefaultWrapper } from '../theme/wrapper';
import { CarouselSlide } from '../components/CarouselSlide';
import Review from '../components/home-page/Review';
import { BookNowBanner } from '../components/home-page/BookNowBanner';
import blackTexture from '../assets/black-texture.png';
import { Toggle } from '../utils';
import { Modal } from '../components/general';

const IntroContainer = styled(DefaultWrapper)`
  max-width: ${width.md}rem;
  padding: 3rem 0;

  @media ${breakpoint.md} {
    padding: 6rem 0;
  }
`;

const Intro = styled.h1`
  font-family: ${typography.family.title};
  font-size: ${typography.size.f4};
  padding: 0 0.5rem;
  text-align: center;
  font-weight: normal;

  @media ${breakpoint.sm} {
    padding: 0 2rem;
  }
  @media ${breakpoint.md} {
    font-size: ${typography.size.f3};
  }

  @media ${breakpoint.lg} {
 
  }
`;


const ColourSpan = styled.div`
  background-color: ${color.grey[15]};
`;

const OverviewSection = styled(DefaultWrapper)`
  padding: 4rem 0;

> div {
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
}
`;

const OverviewContainer = styled.div`
  padding: 2rem;
  display: grid;
  gap: 2rem;
  grid-auto-flow: dense;
  background-color: ${color.white};
  border-radius: ${radius.md};
  box-shadow: ${shadow.box.card};

  @media ${breakpoint.sm} {
    padding: 3rem;
    gap: 3rem;
  }
  
  @media ${breakpoint.md} {
    padding: 4rem;
    gap: 4rem;
    grid-template-columns: 1fr 1fr;
    align-items: start;
  }  
`;

const ModalContent = styled.div`

  h3 {
    margin-bottom: 1em;
  }
  ul {
    margin-inline-start: 2rem;
    margin-bottom: 1em;
  }
`;

const AboutContainer = styled.div`
  width: 80%;
  max-width: ${width.xl}rem;
  margin: 0 auto;
  padding: 3rem 0;

  @media ${breakpoint.lg} {
    
  }
`;

const SectionWrapper = styled.section`
  background-color: ${props => props.bgColor || `${color.white}`};
  background-image: ${props => props.texture ? `url(${blackTexture})` : ''}; 
  background-size: ${props => props.texture ? '300px 300px' : ''};
  padding: 4rem 0;
  text-align: center;
`;

const ReviewsContainer = styled.div`
  margin: 0 auto;
  max-width: ${width.max}rem;
  width: 100%;
  overflow: auto;
  margin-bottom: 1rem;

  @media ${breakpoint.md} {
    width: 80%;
    margin-bottom: 2rem;
  }
`;

const Reviews = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  min-width: 40rem;

  @media ${breakpoint.md} {
    padding: 0;
  }
`;

const MoreReviews = styled(Link)`
  color: ${color.white};
  border-bottom: 2px solid ${color.white};
  text-decoration: none;
  font-weight: bold;
  padding-bottom: 0.25em;
  
  @media (hover:hover) {
    &:hover {
      border-color: ${color.white};
    }
  }
`;

const IndexPage = ({ data }) => {

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': data.site.siteMetadata.title,
    'description': data.site.siteMetadata.description,
    'url': data.site.siteMetadata.siteUrl,
    'logo': `${data.site.siteMetadata.siteUrl}/images/schema/knots.png`
  };

  return (
    <Layout>
      <SEO
        homepage
        title="Knots – Sports Massage Clinic"
        description="A sports massage clinic based in Sheffield; offering the very best treatments and therapy for all sport related injuries, pains, and strains."
        keywords="sports, massage, therapy, treatments, clinic, sheffield, yorkshire"
        schemaMarkup={schema}
        canonical={data.site.siteMetadata.siteUrl}
      />
      <Carousel>
        <CarouselSlide image={data.slide1.childImageSharp.gatsbyImageData} backgroundColor={`${color.grey[15]}`} loading="eager" alt="Interior of Knots sports massage clinic with massage treatment table" />
        <CarouselSlide image={data.slide2.childImageSharp.gatsbyImageData} backgroundColor={`${color.grey[15]}`} loading="eager" alt="Interior of Knots sports massage clinic" />
        <CarouselSlide image={data.slide3.childImageSharp.gatsbyImageData} backgroundColor={`${color.grey[15]}`} loading="eager" alt="Interior of Knots sports massage clinic" />
      </Carousel>
      <IntroContainer>
        <Intro><b>A sports massage clinic based in Sheffield</b>, offering the very best treatments for all injuries, pains, and strains.</Intro>
      </IntroContainer>
      <ColourSpan>
        <TexturedBackground>
          <OverviewSection>
            <OverviewContainer>
              <OverviewContent title="Overview">
                Knots offers the <b>highest quality sports and deep tissue techniques</b>, alongside a specific selection of Holistic treatments that are entirely therapeutic, results orientated, and tailored to you. Targeted interventions and specific work is discussed with you prior to each treatment.
              </OverviewContent>
              <OverviewContent title="Practitioner">
                Your treatment(s) will be conducted by <b>Natalie Kerr</b>, a
                <Toggle>
                  {({ on, toggle }) => (
                    <>
                      {" "}<span onClick={toggle}>fully certified</span>{" "}
                      <Modal on={on} toggle={toggle} backgroundColor={`${color.white}`} width={`${width.sm}rem`}>
                        <ModalContent>
                          <h3>Certified Practitioner: Natalie Kerr</h3>
                          <ul>
                            <li>BSc (Hons) Nutrition</li>
                            <li>PGCE</li>
                            <li>Massage Certified - Premier Training International</li>
                          </ul>
                          <p><i>Accredited with Westfield Health & Health Shield</i></p>
                          </ModalContent>
                      </Modal>
                    </>
                  )}
                </Toggle>
                sports massage practitioner with over 10 years of experience. Natalie’s previous clientele includes; World Snooker, Disney On Ice, Circus Soleil, and Leeds Rhinos.
              </OverviewContent>
            </OverviewContainer>
          </OverviewSection>
        </TexturedBackground>
      </ColourSpan>
      <SectionWrapper bgColor={`${color.white}`} texture>
        <AboutContainer>
          <BookNowBanner />
        </AboutContainer>
      </SectionWrapper>
      <SectionWrapper bgColor={`${color.brand[50]}`} texture>
        <ReviewsContainer>
          <Reviews>
            <Review review="Natalie has really helped me with a couple of problematic long term issues (a sport injury and work related stagnation). The treatments, knowledge, and advice have been invaluable! Highly recommended." reviewer="Dave via Google Reviews" />
            <Review review="I’ve never had a deep tissue massage before so I was slightly nervous as I knew it would be painful but Natalie was really calming and reassuring. The room is beautiful and smelt amazing. I felt really comfortable and relaxed despite the nature of the massage." reviewer="Maria via Google Reviews" />
            <Review review="Great experience with Natalie. She listened to my concerns regarding my injury and the massages have really helped over time." reviewer="David via Google Reviews" />
          </Reviews>
        </ReviewsContainer>
        <MoreReviews to="/reviews/">More Reviews</MoreReviews>
      </SectionWrapper>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`{
  site: site {
    siteMetadata {
      title
      description
      siteUrl
    }
  }
  slide1: file(relativePath: {eq: "home/knots-sports-massage-clinic-sheffield-interior.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  slide2: file(relativePath: {eq: "home/knots-sports-massage-clinic-chair-towels-room.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  slide3: file(relativePath: {eq: "home/knots-sports-massage-clinic-side-table.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 60, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
}
`;
