import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '.';
import { breakpoint, color, typography } from '../../theme';

const Wrapper = styled.div`
  
`;

const Container = styled(Link)`
  background-color: ${props => props.backgroundColor ? props.backgroundColor : ''};
  color: ${props => props.color ? props.color : ''};
  height: 3rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 1.5rem;
  border-radius: 3rem;
  text-decoration: none;
`;

const Text = styled.p`
  font-size: ${typography.size.f6};
  text-align: left;
  font-weight: bold;
  
  @media ${breakpoint.md} {
    font-size: ${typography.size.f5};
  }
`;

const IconContainer = styled.div`
  transform: rotate(-90deg);
  display: flex;
  margin-left: 2rem;

  svg {
    transition: .2s ease;

    ${Container} :hover & {
      transform: translateY(0.25rem);
    }
  }
`;

export const Button = ({url, backgroundColor, color, text}) => {
  return (
    <Wrapper>
      <Container to={url} color={color} backgroundColor={backgroundColor}>
        <Text>{text}</Text>
        <IconContainer>
        <Icon name="arrow" color={color} />
        </IconContainer>
      </Container>
    </Wrapper>
  );
};
