import React from 'react';

const FiveStars = ({ width, height, color, title }) => {

  return (
    <svg width={width} height={height} viewBox="0 0 144 22">
      <title>{title}</title>
      <polygon fill={color} points="45.31 7.18 41.75 -0.06 38.2 7.18 30.25 8.35 36 13.98 34.64 21.94 41.75 18.18 48.87 21.94 47.51 13.98 53.26 8.35 45.31 7.18" />
      <polygon fill={color} points="15.06 7.18 11.5 -0.06 7.95 7.18 0 8.35 5.75 13.98 4.39 21.94 11.5 18.18 18.62 21.94 17.26 13.98 23.01 8.35 15.06 7.18" />
      <polygon fill={color} points="136.05 7.18 132.49 -0.06 128.94 7.18 120.99 8.35 126.74 13.98 125.38 21.94 132.49 18.18 139.61 21.94 138.25 13.98 144 8.35 136.05 7.18" />
      <polygon fill={color} points="105.8 7.18 102.24 -0.06 98.69 7.18 90.74 8.35 96.49 13.98 95.13 21.94 102.24 18.18 109.36 21.94 108 13.98 113.75 8.35 105.8 7.18" />
      <polygon fill={color} points="75.56 7.18 72 -0.06 68.44 7.18 60.49 8.35 66.25 13.98 64.89 21.94 72 18.18 79.11 21.94 77.75 13.98 83.5 8.35 75.56 7.18" />
    </svg>
  );
};

export default FiveStars;
