import styled from 'styled-components';
import { breakpoint } from '.';

export const DefaultGrid = styled.div`
  display: grid;
  grid-auto-flow: ${props => props.dense ? 'dense' : 'unset'};
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4%;
  align-items: ${props => props.center ? 'center' : 'unset'};

  @media ${breakpoint.md} {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2.8%;
  }

  @media ${breakpoint.lg} {
    grid-column-gap: 1.6%;
  }

  > * {
    grid-column: 1 / -1;
  }
`;
